<template>
  <v-tooltip
    top
    :disabled="!name"
    v-bind="$attrs"
  >
    <template #activator="{ on, attrs }">
      <v-avatar
        size="30"
        tile
        v-bind="attrs"
        v-on="on"
      >
        <v-img :src="icon" />
      </v-avatar>
    </template>
    {{ name }}
  </v-tooltip>
</template>
<script>
  export default {
    props: {
      name: {
        type: String,
        default: null,
      },
      icon: {
        type: String,
        default: null,
      },
    },
  }
</script>
